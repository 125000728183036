import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [


        {
            title: "Āzijas gundega jeb Ranunculus",
            image: "https://i.imgur.com/ZiZdn58b.jpg",
            native: "Āzija",
            description: "Vāzē tās var dzīvot līdz pat 10-12 dienām. Tuvākajās dienās tās vēl atvērsies un paliks 2x lielākas kā saņemšanas brīdī",
        },

        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Rozes ir iesakņojušās mūsu pasaulē ar lielo popularitāti un dažādajām nozīmēm. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },
        {
            title: "Sniega bumbas",
            image: "https://i.imgur.com/SDC8Dxzb.jpg",
            native: "Centrāleiropa",
            description: "Plaši izplatīts dārzu krūms Vācijā un Francijā. Pēc ziediem pavasarī, tās vēlāk dod ogas. Sauktas arī kā irbenes.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },
        {
            title: "Krūmroze",
            image: "https://i.imgur.com/GNaV8mUb.jpg",
            native: "Āzija",
            description: "Viens no daudzajiem rožu veidiem. Daudzi mazāki ziedi uz viena stublāja, lieliski piešķir krāsu ziedu kompozīcijām. Ar mazo izmēru tās lieliski papildina lielākus ziedus. To krūmi var sasniegt pat divu metru augstumu. Rozes ilgst līdz vienai nedēļai.",
        },    
    
        {
            title: "Gerberas",
            image: "https://i.imgur.com/kVmQ9t0b.jpg",
            native: "Dienvidāfrika",
            description: "Ja vēlies veikalā Gerberu, tad prasi spider jeb zirnekļveida gerberu, tas apzīmēs ziedlapiņu veidu. Tās līdzīgi kā parastās gerberas ir atrodamas daudzās skaistās krāsās. Gerberas ir mazliet cimperlīgas vāzē, tomēr ja par tām labi rūpējas, tās paliks svaigas līdz pat 7-10 dienām.",
        },   
        {
            title: "Papiruss",
            image: "https://i.imgur.com/jY7dPWrb.jpg",
            native: "Āfrika",
            description: 'No šāda tipa augiem vēsturē tika taisīts pirmais materiāls, uz kā varēja rakstīt - papiruss. Un šo materiālu izdomāja Senajā Ēģiptē. Augam ir ne tikai liela nozīme vēsturē, bet tas ir arī ļoti interesants ziedu kompozīcijās.',
        },
        {
            title: "Dadzis Eryngium Supernova",
            image: "https://i.imgur.com/K3kQtAWb.jpg",
            native: "Austrālija",
            description: 'Vāzē tas izziedēs tumši zilā krāsā, un ar savu izskatu, tie brīnišķīgi atsvaidzina jebkuru kompozīciju vai ziedu pušķi. Tas arī ilgi nenovecos vāzē. Varat pat mēģināt to izžāvēt un izmantot sausziedu kompozīcijām.',
        },
     
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },
        {
            title: "Pitosporas zari",
            image: "https://i.imgur.com/vOdvIqyb.jpg",
            native: "Austrālija",
            description: "Šajos zaros ir kas japānisks. Tos mēdz dēvēt par Pitto. Tie lieliski piedod apjomu ziedu kompozīcijām un bieži tiek izmantoti, kā atbalsts, mazāk stingriem ziediem. Arī šajā kompozīcijā uz tiem atbalstījām sniega bumbas.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },


    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/3e3ff572-11e6-4ff8-8a80-f77a57659e3c/playlist.m3u8"
                        captions="/titles/014.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Papirusa un "spider" gerberu kompozīcija</h2>

                            <p>Šoreiz kompozīcijā esam iekļāvuši jau labi zināmos un iemīļotos ziedus, bet arī dažus jaunus - papirusu un klasiskās un spider jeb zirnekļveida ziedlapiņu gerberas.</p>

                            <p>Kompozīcijā esam izvēlējušies sabalnsētu krāsu ziedus, tāpēc tos būs viegli sakombinēt dažādu formu, augstuma kompozīcijās, ko izvēlēsieties veidot. Tas ko redzat video ir tikai ieteikums. Vēl viens veids kā sakārtot kompozīciju ir pa krāsām - vienā blokā liekat kopā tikai oranžās krāsas ziedus, otrā - baltos un trešā - zaļos; pistāciju lapas un pittosporas zariņus izkārto visā kompozīcijā.</p>

                            <p>Iesakām arī ziedu garumu - piemērot attiecīgai kompozīcijai, tāpēc šoreiz sūtam ziedus, kas nav “nogriezti” jau attiecīgai kompozīcijai, jo domājam, ka daudzas no jums gribēsiet improvizēt :)</p>

                            <p><strong>Mazliet vairāk par Gerberām</strong></p>
                            <p>Lielākā daļa no jums atpazīs gerberas, kas ir dzīvespriecīgas un krāsainas puķes, kas vāzē var nostāvēt ilgi vai pavisam īsu brīdi un nav īsti skaidrs kapēc, vai ne? Tāpēc, lai jūs uzzinātu mazliet vairāk par šo ziedu kopšanu vāzē un dažus interesantus trikus, ko dara floristi, <a href="https://flowersfloralflorist.com/gerbera-daisies/" target="_blank">skaties video</a> (diemžēl tikai angļu valodā) + interesants ir arī rakstā atrodamais video. </p>

                            <ul>
                            <li>Piemērota būs vidēja izmēra vāze ar vidēji platu kaklu, lai ziedu kompozīcija ir kompakta.</li>
                            <li>Lai vāzē visi ziedi nesagāztos vienā čupā, sāc ar zaļumiem. Ņemām garāko zaru čupiņu un izkārtojam pa vāzes aizmuguri un sāniem. Pārējo vari improvizēt.</li>
                            <li>SVARĪGI: Neliekam kātus taisni iekšā vāzē. Zaļumus un ziedus kartojam pēc diognālās metodes, tas ir - liekot ziedu/zaļumu vāzes kreisajā pusē, kāts vāzē ūdenī būs labajā. Varbūt vieglāk iedomāties tos pašus 45 grādus, tā arī liekam puķes vāzē. Kāpēc tā darām? Liekot pa diognāli ziedus un zarus - tie vāzē ūdenī izveidos tādu kā “ligzdu vai tīklu”, kas atbalstīs citus kātus un dēļ šiem atbalstiem iespējams vāzē izkārtot šādas lielas kompozīcijas un ziedi stāvēs izvēlētajās vietās.</li>
                            <li>Ja kāds zieds nenofiksējas vietā, kur vēlies, tad izmanto zaļumus kā papildus atbalstu, pret kuru vari “atstutēt” ziedu.</li>
                            </ul>

                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Ziedi transportēšanas dēļ var izskatīties noliekušies. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tie atgūs savu formu.</li>
                                <li>Ieber ziedu barību vāzē pirms kompozīcijas sākšanas, atceries 1 pulverītis uz 0,5l ūdens.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba! Seko video, lai redzētu darba procesu. </p>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">Aizpildīt anketu</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/ghdTWmUh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
